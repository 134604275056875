.personal-info-form-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	gap: 4rem;
	overflow: hidden;
}

.personal-info-form-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;
}
.personal-info-form-inputs-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 2.5rem;
}
.personal-info-form-inputs {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 2rem;
}
.personal-info-form-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 1rem;
}

.personal-info-form-profile-picture {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40%;
	gap: 0.5rem;
	align-self: flex-start;
}
.profile-image {
	width: 220px;
	height: 220px;
	object-fit: cover;
	border-radius: 50%;
}

.personal-info-form-title {
	align-self: flex-start;
	text-decoration: underline;
	letter-spacing: 2px;
}
