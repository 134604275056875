.create-insured-person-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.form-slider {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: hidden;
	margin-bottom: 3rem;
	position: relative;
}
.hidden {
	visibility: hidden;
	width: 0;
	position: absolute;
}
.visible {
	visibility: visible;
	width: 100%;
}
