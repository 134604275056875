@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,
body,
#root,
.app {
	min-height: 100%;
	width: 100%;
	display: flex;
}

.app {
	background-color: #ececec;
	font-family: 'Jost', sans-serif;
	color: rgb(16, 20, 24);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 3rem 2.5rem 3rem;
	flex: 1 1 auto;
	gap: 1rem;
}
th {
	border: 1px solid #dcdada;
}
td {
	border: 1px solid #dcdada;
}

.loader-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
